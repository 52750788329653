import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { RootState } from '..';
import {
  FeatureFlag,
  ActivityWithExpanded,
  DayReport,
  DateReport,
  Layer,
  LayerGroup,
  MetricAlarmIncident,
  MetricAlarmStateLog,
  MetricAlert,
  NonGTFSRoute,
  PaginationLink,
  PaginationMetadata,
  PeriodReport,
  RedisStatus,
  RouteV1,
  RouteV2,
  RouteDetail,
  RouteReferenceV2,
  RouteTag,
  RouteSegmentV2,
  RouteSummary,
  Stop,
  StopV1,
  StopReferenceV2,
  StopV2,
  StopDetail,
  StopSegmentLoad,
  StopTag,
  Subscription,
  Tag,
  TagDataType,
  TagTypeV2,
  TeamUser,
  TimeRange,
  TripShape,
  TripV1,
  TripV2,
  TripVariant,
  UnreadNotifications,
  User,
  Vehicle,
  IncidentState,
  MetricAlarm,
} from '../types';
import { Point } from 'geojson';
import {
  Insight,
  InsightActivity,
  InsightFilter,
  InsightGrouping,
} from '../../pages/insights/types';
import {
  RelativePeriod,
  cleanDateRange,
  getTotalDaysForDateRange,
} from '../../utils/dates';
import {
  generateCompositeActivity,
  generateCompositeMetrics,
  sleep,
} from '../../utils/utils';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).user.accessToken;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Insight',
    'Subscription',
    'MetricAlarmIncident',
    'MetricAlert',
    'RouteTag',
    'StopTag',
    'Tag',
    'TagType',
    'User',
    'UnreadNotification',
  ],
  endpoints: (builder) => ({
    refreshToken: builder.mutation<
      { access: string; refresh: string },
      { refreshToken: string; accessToken?: string }
    >({
      query: ({ refreshToken, accessToken }) => ({
        url: '/api/token/refresh/',
        method: 'POST',
        body: JSON.stringify({ refresh: refreshToken, access: accessToken }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    login: builder.mutation<
      { access: string; refresh: string },
      { username: string; password: string }
    >({
      query: ({ username, password }) => ({
        url: '/api/token/',
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    logout: builder.mutation<void, { refresh: string }>({
      query: ({ refresh }) => ({
        url: '/api/token/blacklist/',
        method: 'POST',
        body: JSON.stringify({ refresh }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    changePassword: builder.mutation<
      { access: string; refresh: string },
      { currentPassword: string; newPassword: string }
    >({
      query: ({ currentPassword, newPassword }) => ({
        url: '/me/reset_password',
        method: 'POST',
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    resetPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: '/api/password_reset/',
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    getMe: builder.query<User, void>({
      query: () => '/me',
      providesTags: ['User'],
    }),
    getUsers: builder.query<TeamUser[], { agencyId: string }>({
      query: ({ agencyId }) => ({
        url: '/users',
        method: 'POST',
        body: { agency_id: agencyId },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
      keepUnusedDataFor: 3600,
    }),
    addUser: builder.mutation<
      void,
      { agencyId: string; email: string; roleId: string }
    >({
      query: ({ agencyId, email, roleId }) => ({
        url: '/users/add',
        method: 'POST',
        body: {
          agency_id: agencyId,
          email,
          role_id: roleId,
        },
      }),
    }),
    getFeatures: builder.query<FeatureFlag[], { agencyId: string }>({
      query: ({ agencyId }) => `/v2/agencies/${agencyId}/features`,
      keepUnusedDataFor: 3600,
      transformResponse: (response: { data: FeatureFlag[] }) => response.data,
    }),
    getRouteReferences: builder.query<
      RouteReferenceV2[],
      { agencyId: string; linked: boolean }
    >({
      async queryFn({ agencyId, linked }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<RouteReferenceV2>(
          baseQuery,
          {
            url: `/v2/agencies/${agencyId}/routes/references`,
            method: 'POST',
            body: { linked },
          },
          'data.routes'
        );
      },
    }),
    getStopReferences: builder.query<
      StopReferenceV2[],
      { agencyId: string; linked: boolean }
    >({
      async queryFn({ agencyId, linked }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<StopReferenceV2>(
          baseQuery,
          {
            url: `/v2/agencies/${agencyId}/stops/references`,
            method: 'POST',
            body: { linked },
          },
          'data.stops'
        );
      },
    }),
    deleteDataLink: builder.mutation<
      void,
      { agencyId: string; referenceId: string; referenceType: string }
    >({
      query: ({ agencyId, referenceId, referenceType }) => ({
        url: '/agencies/references/unlink',
        method: 'POST',
        body: {
          agency_id: agencyId,
          reference_id: referenceId,
          reference_type: referenceType,
        },
      }),
    }),
    updateUser: builder.mutation<User, { firstName: string; lastName: string }>(
      {
        query: ({ firstName, lastName }) => ({
          url: '/me/edit',
          method: 'POST',
          body: { first_name: firstName, last_name: lastName },
        }),
        invalidatesTags: ['User'],
      }
    ),
    getTagTypeV2: builder.query<
      TagTypeV2,
      { agencyId: string; tagTypeId: string }
    >({
      query: ({ agencyId, tagTypeId }) => ({
        url: `/v2/agencies/${agencyId}/tagtypes/${tagTypeId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3600,
      providesTags: (result) =>
        result ? [{ type: 'TagType' as const, id: result.id }] : [],
    }),
    getTagTypesV2: builder.query<
      TagTypeV2[],
      { agencyId: string; includeTaggedEntities?: boolean }
    >({
      query: ({ agencyId, includeTaggedEntities }) =>
        `/v2/agencies/${agencyId}/tagtypes?includeTaggedEntities=${!!includeTaggedEntities}`,
      keepUnusedDataFor: 3600,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'TagType' as const, id })),
              'TagType',
            ]
          : ['TagType'],
      transformResponse: (response: { data: TagTypeV2[] }) => response.data,
    }),
    addTagType: builder.mutation<
      TagTypeV2,
      { agencyId: string; name: string; dataType: TagDataType }
    >({
      query: ({ agencyId, name, dataType }) => ({
        url: `/v2/agencies/${agencyId}/tagtypes`,
        method: 'POST',
        body: { name, dataType },
      }),
      invalidatesTags: ['TagType'],
    }),
    updateTagType: builder.mutation<
      TagTypeV2,
      { agencyId: string; tagTypeId: string; name: string }
    >({
      query: ({ agencyId, tagTypeId, name }) => ({
        url: `/v2/agencies/${agencyId}/tagtypes/${tagTypeId}`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: (_r, _e, arg) => [
        { type: 'TagType', id: arg.tagTypeId },
      ],
    }),
    deleteTagType: builder.mutation<
      void,
      { agencyId: string; tagTypeId: string }
    >({
      query: ({ agencyId, tagTypeId }) => ({
        url: `/v2/agencies/${agencyId}/tagtypes/${tagTypeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_r, _e, arg) => [
        { type: 'TagType', id: arg.tagTypeId },
      ],
    }),
    getTags: builder.query<Tag[], { agencyId: string; tagTypeId: string }>({
      async queryFn(
        { agencyId, tagTypeId },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await fetchAllPages<Tag>(baseQuery, {
          url: `/v2/agencies/${agencyId}/tagtypes/${tagTypeId}/tags`,
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Tag' as const,
                id,
              })),
              'Tag',
            ]
          : ['Tag'],
    }),
    addTags: builder.mutation<
      Tag,
      { agencyId: string; tagTypeId: string; objectIds: string[] }
    >({
      query: ({ agencyId, tagTypeId, objectIds }) => ({
        url: `/v2/agencies/${agencyId}/tags`,
        method: 'POST',
        body: { tagTypeId, objectIds },
      }),
      invalidatesTags: ['RouteTag', 'StopTag', 'Tag'],
    }),
    deleteTagsV2: builder.mutation<
      void,
      { agencyId: string; tagIds: string[] }
    >({
      query: ({ agencyId, tagIds }) => ({
        url: `/v2/agencies/${agencyId}/tags`,
        method: 'DELETE',
        body: { tagIds },
      }),
      invalidatesTags: ['RouteTag', 'StopTag', 'Tag', 'TagType'],
    }),
    getLayerGroups: builder.query<LayerGroup[], { agencyId: string }>({
      async queryFn({ agencyId }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<LayerGroup>(baseQuery, {
          url: `/v1/agencies/${agencyId}/layergroups`,
          method: 'GET',
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getLayerGroup: builder.query<
      LayerGroup,
      { agencyId: string; layerGroupId: string }
    >({
      query: ({ agencyId, layerGroupId }) => ({
        url: `/v1/agencies/${agencyId}/layergroups/${layerGroupId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3600,
    }),
    getLayerSearch: builder.query<
      Layer[],
      {
        agencyId: string;
        layerGroupId?: string;
        layerIds?: string[];
        dateRange?: RelativeDateRange;
        filters?: InsightFilter[];
      }
    >({
      async queryFn(
        { agencyId, layerGroupId, layerIds = [], dateRange, filters },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const url = layerGroupId
          ? `/v1/agencies/${agencyId}/layergroups/${layerGroupId}/layers`
          : `/v1/agencies/${agencyId}/layers:search`;
        return await fetchAllPages<Layer>(baseQuery, {
          url,
          method: 'POST',
          body: {
            layerIds,
            dateRange: cleanDateRange(
              dateRange ?? { dateRangeType: RelativePeriod.FOUR_WEEKS }
            ),
            filters,
          },
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getVehicles: builder.query<Vehicle[], { agencyId: string }>({
      async queryFn({ agencyId }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<Vehicle>(baseQuery, {
          url: `/v2/agencies/${agencyId}/vehicles`,
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getVehicleSearch: builder.query<
      Vehicle[],
      { agencyId: string; vehicleId: string }
    >({
      async queryFn(
        { agencyId, vehicleId },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await fetchAllPages<Vehicle>(baseQuery, {
          url: `/v2/agencies/${agencyId}/vehicles:search`,
          method: 'POST',
          body: { vehicleId },
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getMetricAlarms: builder.query<MetricAlarm[], { agencyId: string }>({
      async queryFn({ agencyId }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<MetricAlarm>(baseQuery, {
          url: `/v2/agencies/${agencyId}/metrics/alarms`,
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getMetricAlarmIncidents: builder.query<
      { data: MetricAlarmIncident[]; metadata: PaginationMetadata },
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        limit?: number;
        cursor?: string;
        statuses?: IncidentState[];
        entityIds?: string[];
        metricAlarmIds?: string[];
        muted: boolean | null;
        includeTotalCount?: boolean;
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
      }
    >({
      query: ({
        agencyId,
        dateRange,
        limit,
        cursor,
        statuses,
        entityIds,
        metricAlarmIds,
        muted,
        includeTotalCount,
        orderBy,
        orderByDirection,
      }) => {
        const queryParams = queryString.stringify({
          limit,
          cursor,
          includeTotalCount,
          orderBy: orderBy ? `${orderBy}:${orderByDirection}` : undefined,
        });
        return {
          url: `/v2/agencies/${agencyId}/metrics/alarms/incidents?${queryParams}`,
          method: 'POST',
          body: {
            dateRange,
            statuses,
            metricAlarmIds,
            muted,
            entityIds,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map((incident) => ({
                type: 'MetricAlarmIncident' as const,
                id: incident.entity.id,
              })),
              'MetricAlarmIncident',
            ]
          : ['MetricAlarmIncident'],
    }),
    downloadMetricAlarmIncidents: builder.mutation<
      { download_link: string },
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        statuses?: IncidentState[];
        entityIds?: string[];
        metricAlarmIds?: string[];
        muted: boolean | null;
      }
    >({
      async queryFn(
        { agencyId, dateRange, statuses, entityIds, metricAlarmIds, muted },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<{
          download_link: string;
        }>(baseQuery, {
          url: `/v2/agencies/${agencyId}/metrics/alarms/incidents:download`,
          method: 'POST',
          body: {
            dateRange,
            statuses,
            metricAlarmIds,
            muted,
            entityIds,
          },
        });
      },
    }),
    getMetricAlarmRecords: builder.query<
      { data: MetricAlarmStateLog[]; metadata: PaginationMetadata },
      {
        agencyId: string;
        cursor?: string;
        dateRange: RelativeDateRange;
        entityId: string;
        includeTotalCount?: boolean;
        limit?: number;
        orderBy?: string;
        orderByDirection?: string;
      }
    >({
      query: ({
        agencyId,
        cursor,
        dateRange,
        entityId,
        includeTotalCount,
        limit,
        orderBy,
        orderByDirection,
      }) => {
        const queryParams = queryString.stringify({
          limit,
          cursor,
          includeTotalCount,
          orderBy: orderBy ? `${orderBy}:${orderByDirection}` : undefined,
        });
        return {
          url: `/v2/agencies/${agencyId}/metrics/entities/${entityId}/logs?${queryParams}`,
          method: 'POST',
          body: {
            dateRange,
          },
        };
      },
    }),
    muteMetricAlarmEntity: builder.mutation<
      void,
      { agencyId: string; entityId: string }
    >({
      query: ({ agencyId, entityId }) => ({
        url: `/v2/agencies/${agencyId}/metrics/entities/${entityId}/mute`,
        method: 'POST',
      }),
      invalidatesTags: ['MetricAlarmIncident'],
    }),
    unmuteMetricAlarmEntity: builder.mutation<
      void,
      { agencyId: string; entityId: string }
    >({
      query: ({ agencyId, entityId }) => ({
        url: `/v2/agencies/${agencyId}/metrics/entities/${entityId}/unmute`,
        method: 'POST',
      }),
      invalidatesTags: ['MetricAlarmIncident'],
    }),
    getMetricAlert: builder.query<
      MetricAlert,
      { agencyId: string; alertId: string }
    >({
      query: ({ agencyId, alertId }) =>
        `/v2/agencies/${agencyId}/metrics/alerts/${alertId}`,
      keepUnusedDataFor: 3600,
    }),
    getMetricAlerts: builder.query<MetricAlert[], { agencyId: string }>({
      async queryFn({ agencyId }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<MetricAlert>(baseQuery, {
          url: `/v2/agencies/${agencyId}/metrics/alerts`,
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'MetricAlert' as const, id })),
              'MetricAlert',
            ]
          : ['MetricAlert'],
    }),
    addMetricAlert: builder.mutation<
      MetricAlert,
      { agencyId: string; name: string; diagnosticMetricAlarmIds: string[] }
    >({
      query: ({ agencyId, name, diagnosticMetricAlarmIds }) => ({
        url: `/v2/agencies/${agencyId}/metrics/alerts`,
        method: 'POST',
        body: { name, diagnosticMetricAlarmIds },
      }),
      invalidatesTags: ['MetricAlert'],
    }),
    updateMetricAlert: builder.mutation<
      MetricAlert,
      {
        agencyId: string;
        alertId: string;
        name: string;
        diagnosticMetricAlarmIds: string[];
      }
    >({
      query: ({ agencyId, name, diagnosticMetricAlarmIds, alertId }) => ({
        url: `/v2/agencies/${agencyId}/metrics/alerts/${alertId}`,
        method: 'PUT',
        body: { name, diagnosticMetricAlarmIds },
      }),
      invalidatesTags: ['MetricAlert'],
    }),
    deleteMetricAlert: builder.mutation<
      void,
      {
        agencyId: string;
        alertId: string;
      }
    >({
      query: ({ agencyId, alertId }) => ({
        url: `/v2/agencies/${agencyId}/metrics/alerts/${alertId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MetricAlert'],
    }),
    getDays: builder.query<
      DayReport[],
      {
        agencyId: string;
        dateRange: DateRange;
        expanded?: boolean;
        direction?: number;
        variant?: string;
        routeIds?: string[];
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          dateRange,
          expanded,
          direction,
          variant,
          routeIds,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<DayReport[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/days`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            direction,
            variant,
            routeIds,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getDates: builder.query<
      DateReport[],
      {
        agencyId: string;
        dateRange: DateRange;
        expanded?: boolean;
        direction?: number;
        variant?: string;
        routeIds?: string[];
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          dateRange,
          expanded,
          direction,
          variant,
          routeIds,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<DateReport[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/dates`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            direction,
            variant,
            routeIds,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getPeriods: builder.query<
      PeriodReport,
      {
        agencyId: string;
        timeRangeId?: string;
        dateRange: DateRange;
        expanded?: boolean;
        direction?: number;
        variant?: string;
        routeIds?: string[];
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          timeRangeId,
          dateRange,
          expanded,
          direction,
          variant,
          routeIds,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<PeriodReport>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/periods/${timeRangeId}`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            direction,
            variant,
            routeIds,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getSystem: builder.query<
      ActivityWithExpanded,
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        expanded?: boolean;
        direction?: number;
        variant?: string;
        routeIds?: string[];
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          dateRange,
          direction,
          expanded,
          variant,
          routeIds,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<ActivityWithExpanded>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/system`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            direction,
            expanded,
            variant,
            routeIds,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeMetrics(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getRouteSummaryV2: builder.query<
      RouteSummary[],
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        expanded?: boolean;
        variant?: string;
        routeIds?: string[];
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        { agencyId, dateRange, expanded, variant, routeIds, stopIds, tripIds },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<RouteSummary[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/routes`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            variant,
            routeIds,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getRoutes: builder.query<
      RouteV2[],
      { agencyId: string; dateRange: RelativeDateRange }
    >({
      query: ({ agencyId, dateRange }) => ({
        url: `/v2/agencies/${agencyId}/routes`,
        method: 'POST',
        body: { dateRange: cleanDateRange(dateRange) },
      }),
      transformResponse: (response: { data: { routes: RouteV2[] } }) =>
        response.data.routes,
      keepUnusedDataFor: 3600,
    }),
    getRouteDescription: builder.query<RouteV1, { routeId: string }>({
      query: ({ routeId }) => ({
        url: `/v1/routes/${routeId}`,
        method: 'GET',
      }),
    }),
    getRouteDetailsV2: builder.query<
      RouteDetail,
      {
        agencyId: string;
        routeId: string;
        dateRange: RelativeDateRange;
        direction?: number;
        expanded?: boolean;
        variant?: string;
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          dateRange,
          direction,
          expanded,
          variant,
          routeId,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<RouteDetail>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/routes/${routeId}`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            direction,
            expanded,
            variant,
            routeId,
            stopIds,
            tripIds,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getNonRoutedRouteDetails: builder.query<
      NonGTFSRoute,
      { agencyId: string; routeId: string; dateRange: DateRange }
    >({
      query: ({ agencyId, routeId, dateRange }) => ({
        url: `/boardings/non_gtfs_routes/${routeId}`,
        method: 'POST',
        body: {
          agency_id: agencyId,
          firstDateRange: cleanDateRange(dateRange),
        },
      }),
    }),
    getRouteLoadV2: builder.query<
      StopSegmentLoad[],
      {
        agencyId: string;
        routeId: string;
        dateRange: DateRange;
        expanded?: boolean;
        direction?: number;
        variant?: string;
        stopIds?: string[];
        tripIds?: string[];
      }
    >({
      async queryFn(
        {
          agencyId,
          routeId,
          dateRange,
          expanded,
          direction,
          variant,
          stopIds,
          tripIds,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<StopSegmentLoad[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/routes/${routeId}/load`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            direction,
            variant,
            stopIds,
            tripIds,
          },
        });
      },
    }),
    getRouteSegments: builder.query<
      RouteSegmentV2[],
      {
        agencyId: string;
        routeId: string;
        dateRange: DateRange;
        direction?: number;
        variant?: string;
        tripIds?: string[];
      }
    >({
      async queryFn(
        { agencyId, routeId, dateRange, direction, variant, tripIds },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<RouteSegmentV2[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/routes/${routeId}/segments`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            direction,
            variant,
            tripIds,
          },
        });
      },
    }),
    getRouteShapes: builder.query<
      { [key: string]: TripShape[] },
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        routeIds?: string[];
        direction?: number;
        variant?: string;
        filters?: InsightFilter[];
      }
    >({
      async queryFn(
        { agencyId, dateRange, routeIds, direction, variant, filters },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<{ [key: string]: TripShape[] }>(
          baseQuery,
          {
            url: `/v2/agencies/${agencyId}/routes/shapes`,
            method: 'POST',
            body: {
              dateRange: cleanDateRange(dateRange),
              routeIds,
              direction,
              variant,
              filters,
            },
          }
        );
      },
    }),
    getTripSummary: builder.query<
      { trips: TripV2[] },
      {
        agencyId: string;
        routeId: string;
        dateRange: DateRange;
        expanded?: boolean;
        variant?: string;
        direction?: number;
      }
    >({
      async queryFn(
        { agencyId, dateRange, direction, expanded, variant, routeId },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<{ trips: TripV2[] }>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/routes/${routeId}/trips`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            direction,
            expanded,
            variant,
            routeId,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getTripDescription: builder.query<TripV1, { tripId: string }>({
      query: ({ tripId }) => ({
        url: `/v1/trips/${tripId}`,
        method: 'GET',
      }),
    }),
    getTripDetails: builder.query<
      { trip: TripV2; stops: StopV2[] },
      {
        agencyId: string;
        routeId: string;
        tripId: string;
        dateRange: DateRange;
        expanded?: boolean;
      }
    >({
      async queryFn(
        { agencyId, dateRange, expanded, routeId, tripId },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<{
          trip: TripV2;
          stops: StopV2[];
        }>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/routes/${routeId}/trips/${tripId}`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
            routeId,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getStopSummaryV2: builder.query<
      StopV2[],
      { agencyId: string; dateRange: DateRange; expanded?: boolean }
    >({
      async queryFn(
        { agencyId, dateRange, expanded },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<StopV2[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/stops`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getStopDetail: builder.query<
      StopDetail[],
      {
        agencyId: string;
        dateRange: DateRange;
        stopId: string;
        expanded?: boolean;
      }
    >({
      async queryFn(
        { agencyId, dateRange, stopId, expanded },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const result = await asyncReqResQuery<StopDetail[]>(baseQuery, {
          url: `/v2/agencies/${agencyId}/boardings/stops/${stopId}`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            expanded,
          },
        });
        if (result.data) {
          result.data = generateCompositeActivity(result.data);
        }
        return result;
      },
      keepUnusedDataFor: 3600,
    }),
    getStop: builder.query<StopV1, { stopId: string }>({
      query: ({ stopId }) => ({
        url: `/v1/stops/${stopId}`,
        method: 'GET',
      }),
    }),
    getStops: builder.query({
      query: ({ agencyId }) => ({
        url: '/agencies/master/stops',
        method: 'POST',
        body: { agency_id: agencyId },
      }),
      transformResponse: (response: { data: { master_stops: Stop[] } }) =>
        response.data.master_stops,
      keepUnusedDataFor: 3600,
    }),
    getStopPoints: builder.query<
      { [key: string]: Point },
      { agencyId: string; dateRange: RelativeDateRange; stopIds?: string[] }
    >({
      async queryFn(
        { agencyId, dateRange, stopIds },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<{ [key: string]: Point }>(baseQuery, {
          url: `/v2/agencies/${agencyId}/stops/points`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            stopIds,
          },
        });
      },
    }),
    getStopPointsV3: builder.query<
      { [key: string]: { stopPoint: Point; stopName: string } },
      {
        agencyId: string;
        dateRange: RelativeDateRange;
        stopIds?: string[];
        filters?: InsightFilter[];
      }
    >({
      async queryFn(
        { agencyId, dateRange, stopIds, filters },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<{
          [key: string]: { stopPoint: Point; stopName: string };
        }>(baseQuery, {
          url: `/v3/agencies/${agencyId}/stops/points`,
          method: 'POST',
          body: {
            dateRange: cleanDateRange(dateRange),
            stopIds,
            filters,
          },
        });
      },
    }),
    getRouteTags: builder.query<RouteTag[], { routeId: string }>({
      query: ({ routeId }) => ({
        url: `/agencies/master/routes/${routeId}/tags`,
        method: 'GET',
      }),
      transformResponse: (response: { data: RouteTag[] }) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'RouteTag' as const, id })),
              'RouteTag',
            ]
          : ['RouteTag'],
    }),
    getStopTags: builder.query<StopTag[], { stopId: string }>({
      query: ({ stopId }) => ({
        url: `/agencies/master/stops/${stopId}/tags`,
        method: 'GET',
      }),
      transformResponse: (response: { data: StopTag[] }) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StopTag' as const, id })),
              'StopTag',
            ]
          : ['StopTag'],
    }),
    getTimeRanges: builder.query<TimeRange[], { agencyId: string }>({
      query: ({ agencyId }) => ({
        url: '/agencies/timeranges',
        method: 'POST',
        body: { agency_id: agencyId },
      }),
      keepUnusedDataFor: 3600,
    }),
    getTripVariant: builder.query<TripV1, { variantId: string }>({
      query: ({ variantId }) => ({
        url: `v1/trips/variants/${variantId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 3600,
    }),
    getTripVariants: builder.query<
      TripVariant[],
      {
        agencyId: string;
        routeId: string;
        dateRange: DateRange;
        tripIds?: string[];
      }
    >({
      async queryFn(
        { agencyId, routeId, dateRange, tripIds },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<TripVariant[]>(baseQuery, {
          url: `v2/agencies/${agencyId}/routes/${routeId}/trip_variants`,
          method: 'POST',
          body: { dateRange: cleanDateRange(dateRange), tripIds },
        });
      },
      keepUnusedDataFor: 3600,
    }),
    getInsights: builder.query<Insight[], { agencyId: string }>({
      async queryFn({ agencyId }, _queryApi, _extraOptions, baseQuery) {
        return await fetchAllPages<Insight>(
          baseQuery,
          {
            url: `/v1/agencies/${agencyId}/insights`,
          },
          'insights'
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Insight' as const,
                id,
              })),
              'Insight',
            ]
          : ['Insight'],
    }),
    getInsight: builder.query<Insight, { agencyId: string; insightId: string }>(
      {
        query: ({ agencyId, insightId }) => ({
          url: `/v1/agencies/${agencyId}/insights/${insightId}`,
          method: 'GET',
        }),
        providesTags: (result) =>
          result
            ? [{ type: 'Insight' as const, id: result.id }, 'Insight']
            : ['Insight'],
      }
    ),
    addInsight: builder.mutation<
      Insight,
      { agencyId: string; insight: Partial<Insight> }
    >({
      query: ({ agencyId, insight }) => ({
        url: `/v1/agencies/${agencyId}/insights`,
        method: 'POST',
        body: insight,
      }),
      invalidatesTags: ['Insight'],
    }),
    updateInsight: builder.mutation<
      Insight,
      { agencyId: string; insightId: string; insight: Omit<Insight, 'id'> }
    >({
      query: ({ agencyId, insightId, insight }) => ({
        url: `/v1/agencies/${agencyId}/insights/${insightId}`,
        method: 'PUT',
        body: insight,
      }),
      invalidatesTags: (_r, _e, arg) => [
        { type: 'Insight', id: arg.insightId },
      ],
    }),
    deleteInsight: builder.mutation<
      undefined,
      { agencyId: string; insightId: string }
    >({
      query: ({ agencyId, insightId }) => ({
        url: `/v1/agencies/${agencyId}/insights/${insightId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_r, _e, arg) => [
        { type: 'Insight', id: arg.insightId },
      ],
    }),
    getInsightResults: builder.query<
      {
        insight: Insight;
        data: InsightActivity[];
        links: PaginationLink[];
        metadata?: PaginationMetadata;
      },
      {
        agencyId: string;
        insight: Insight;
        limit?: number;
        offset?: number;
        orderBy?: string;
        orderByDirection?: 'asc' | 'desc';
        coalesceBy?: InsightGrouping | 'system';
      }
    >({
      async queryFn(
        {
          agencyId,
          insight,
          limit,
          offset,
          orderBy,
          orderByDirection,
          coalesceBy,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        const queryParams = queryString.stringify({
          limit,
          offset,
          orderBy: orderBy ? `${orderBy}:${orderByDirection}` : undefined,
          coalesceBy,
        });
        return await asyncReqResQuery<{
          insight: Insight;
          data: InsightActivity[];
          links: PaginationLink[];
          metadata?: PaginationMetadata;
        }>(baseQuery, {
          url: `/v1/agencies/${agencyId}/insights:run?${queryParams}`,
          method: 'POST',
          body: insight,
        });
      },
      keepUnusedDataFor: 3600,
    }),
    addInsightFavorite: builder.mutation<
      void,
      { agencyId: string; insightId: string }
    >({
      query: ({ agencyId, insightId }) => ({
        url: `/v1/agencies/${agencyId}/insights/${insightId}/favorites`,
        method: 'POST',
      }),
      invalidatesTags: ['Insight'],
    }),
    deleteInsightFavorite: builder.mutation<
      void,
      { agencyId: string; insightId: string }
    >({
      query: ({ agencyId, insightId }) => ({
        url: `/v1/agencies/${agencyId}/insights/${insightId}/favorites`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Insight'],
    }),
    getInsightSubscriptions: builder.query<
      { data: Subscription[] },
      { agencyId: string; insightId: string }
    >({
      query: ({ agencyId, insightId }) => ({
        url: `/v2/agencies/${agencyId}/insights/${insightId}/subscriptions`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Subscription' as const,
                id,
              })),
              'Subscription',
            ]
          : ['Subscription'],
    }),
    addInsightSubscription: builder.mutation<
      Subscription,
      {
        agencyId: string;
        insightId: string;
        subscription: Partial<Subscription>;
      }
    >({
      query: ({ agencyId, insightId, subscription }) => ({
        url: `/v2/agencies/${agencyId}/insights/${insightId}/subscriptions`,
        method: 'POST',
        body: subscription,
      }),
      invalidatesTags: ['Subscription'],
    }),
    deleteInsightSubscriptions: builder.mutation<
      void,
      { agencyId: string; insightId: string }
    >({
      query: ({ agencyId, insightId }) => ({
        url: `/v2/agencies/${agencyId}/insights/${insightId}/subscriptions`,
        method: 'DELETE',
      }),
      onQueryStarted({ agencyId, insightId }, { dispatch, queryFulfilled }) {
        // optimistically wipe cache entry for subscriptions
        const patchResult = dispatch(
          api.util.updateQueryData(
            'getInsightSubscriptions',
            { agencyId, insightId },
            () => ({
              data: [],
            })
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['Subscription'],
    }),
    getMetricAlertSubscriptions: builder.query<
      { data: Subscription[] },
      { agencyId: string; alertId: string }
    >({
      query: ({ agencyId, alertId }) => ({
        url: `/v2/agencies/${agencyId}/metrics/alerts/${alertId}/subscriptions`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Subscription' as const,
                id,
              })),
              'Subscription',
            ]
          : ['Subscription'],
    }),
    addMetricAlertSubscription: builder.mutation<
      Subscription,
      {
        agencyId: string;
        alertId: string;
        subscription: Partial<Subscription>;
      }
    >({
      query: ({ agencyId, alertId, subscription }) => ({
        url: `/v2/agencies/${agencyId}/metrics/alerts/${alertId}/subscriptions`,
        method: 'POST',
        body: subscription,
      }),
      invalidatesTags: ['Subscription'],
    }),
    updateSubscription: builder.mutation<
      Subscription,
      { agencyId: string; subscription: Subscription }
    >({
      query: ({ agencyId, subscription }) => ({
        url: `/v2/agencies/${agencyId}/subscriptions/${subscription.id}`,
        method: 'PUT',
        body: subscription,
      }),
      invalidatesTags: ['Insight', 'Subscription'],
    }),
    deleteSubscription: builder.mutation<
      void,
      { agencyId: string; subscriptionId: string }
    >({
      query: ({ agencyId, subscriptionId }) => ({
        url: `/v2/agencies/${agencyId}/subscriptions/${subscriptionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Insight', 'Subscription'],
    }),
    postTrackingEvent: builder.mutation<
      { status: string },
      { agencyId?: string; name: string; properties?: Record<string, any> }
    >({
      query: ({ agencyId, name, properties = {} }) => ({
        url: '/track_event',
        method: 'POST',
        body: { name, properties, agency_id: agencyId },
      }),
    }),
    getUnreadNotifications: builder.query<UnreadNotifications, void>({
      query: () => ({
        url: '/v1/notifications/unread_list',
        method: 'GET',
        providesTags: ['UnreadNotification'],
      }),
    }),
    markNotificationAsRead: builder.mutation<void, { slug: string }>({
      query: ({ slug }) => ({
        url: `/v1/notifications/${slug}/mark_as_read`,
        method: 'POST',
      }),
      invalidatesTags: ['UnreadNotification'],
    }),
    markAllNotificationsAsRead: builder.mutation<void, void>({
      query: () => ({
        url: '/v1/notifications/mark_all_as_read',
        method: 'POST',
      }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        // optimistically wipe cache entry for unread notifications
        const patchResult = dispatch(
          api.util.updateQueryData('getUnreadNotifications', undefined, () => ({
            unread_count: 0,
            unread_list: [],
          }))
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: ['UnreadNotification'],
    }),
    getSubscriptions: builder.query<
      {
        insightSubscriptions: Subscription[];
        diagnosticMetricAlertSubscriptions: Subscription[];
      },
      { email: string; subscriptionId: string }
    >({
      query: ({ email, subscriptionId }) => ({
        url: `/v2/subscriptions?email=${email}&subscriptionId=${subscriptionId}`,
        method: 'GET',
      }),
    }),
    deleteSubscriptions: builder.mutation<
      void,
      { email: string; subscriptionIds: string[] }
    >({
      query: ({ email, subscriptionIds }) => ({
        url: '/v2/unsubscribe',
        method: 'POST',
        body: { email, subscriptionIds },
      }),
    }),
    downloadInsight: builder.mutation<
      { download_link: string },
      { agencyId: string; insight: Insight }
    >({
      async queryFn(
        { agencyId, insight },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        return await asyncReqResQuery<{
          download_link: string;
        }>(baseQuery, {
          url: `/v1/agencies/${agencyId}/insights:download`,
          method: 'POST',
          body: insight,
        });
      },
    }),
  }),
});

export const fetchAllPages = async <T>(
  baseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
  >,
  queryArgs: string | FetchArgs,
  key: string = 'data'
) => {
  const isStringArgs = typeof queryArgs === 'string';
  try {
    const getResultPage = async (
      results: T[],
      cursor?: string
    ): Promise<T[]> => {
      let endpoint = isStringArgs ? queryArgs : queryArgs.url;
      if (cursor) {
        endpoint += `?cursor=${cursor}`;
      }

      const result = await baseQuery(
        isStringArgs ? endpoint : { ...queryArgs, url: endpoint }
      );
      if (result.error) {
        throw result.error;
      }

      const data = result.data as any;
      const getValue = (data: any, path: string): any => {
        if (!path) {
          return data;
        }
        const properties = path.split('.');
        return getValue(data[properties.shift()!], properties.join('.'));
      };

      results = results.concat(getValue(data, key) as T[]);
      const links = data?.metadata?.links ?? data.links;
      const nextLink = (links as PaginationLink[]).find(
        (link) => link.rel === 'next'
      );
      if (nextLink) {
        const match = nextLink.href.match(/cursor=([^&]*)/);
        if (match && match[1]) {
          return getResultPage(results, match[1]);
        }
      }

      return results;
    };

    const results = await getResultPage([]);
    return { data: results };
  } catch (error) {
    return { error: error as FetchBaseQueryError };
  }
};

export const fetchRedisResult = async <T>(
  baseQuery: (arg: string | FetchArgs) => any, // RTK Query does not export this type, proceed with caution
  redisId: string
): Promise<T | null> => {
  let redisStatus: RedisStatus = { status: 'started', result: {} };
  while (['queued', 'started', 'deferred'].includes(redisStatus.status)) {
    const redisResult = await baseQuery({
      url: `/redis/status/${redisId}`,
      method: 'GET',
    });

    if (redisResult.error) {
      throw redisResult.error;
    }

    redisStatus = redisResult.data as RedisStatus;
    await sleep(2000);
  }

  if (redisStatus.status === 'finished') {
    return redisStatus.result.data as T;
  }
  return null;
};

export const fetchJobResult = async <T>(
  baseQuery: (arg: string | FetchArgs) => any,
  statusUrl: string
): Promise<T | null> => {
  const MAX_RETRIES = 50;
  const SLOW_FACTOR = 1.1;

  let jobStatus: { status: string; jobData: T | null } = {
    status: 'queued',
    jobData: null,
  };
  let retries = 0;
  while (
    ['queued', 'started', 'deferred'].includes(jobStatus.status) &&
    retries < MAX_RETRIES
  ) {
    const result = await baseQuery({
      url: statusUrl,
      method: 'GET',
    });

    if (result.error) {
      throw result.error;
    }

    jobStatus = result.data.data;
    await sleep(Math.min(1000 * SLOW_FACTOR ** retries, 5000));
    retries++;
  }

  if (jobStatus.status === 'finished') {
    return jobStatus.jobData as T;
  }
  return null;
};

export const asyncReqResQuery = async <T>(
  baseQuery: (
    arg: string | FetchArgs
  ) => MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
  >,
  queryArgs: string | FetchArgs
) => {
  const startTime = performance.now();

  const reportApiResponse = (result: string) => {
    const endTime = performance.now();
    const url = typeof queryArgs === 'string' ? queryArgs : queryArgs.url;
    newrelic.addPageAction('apiResponse', {
      duration: endTime - startTime,
      url,
      result,
    });

    if (url.includes('insights:run')) {
      const insight = (queryArgs as FetchArgs).body as Insight;
      if (insight) {
        const dateRange = insight.dateRanges?.[0];
        newrelic.addPageAction('insightsApiResponse', {
          duration: endTime - startTime,
          url,
          result,
          insightType: insight.insightType,
          insightGrouping: insight.groupBy ?? 'none',
          dateRangeType: dateRange?.dateRangeType ?? 'custom',
          daysCount: dateRange ? getTotalDaysForDateRange(dateRange) : 0,
          filterCount: insight.filters?.length ?? 0,
        });
      }
    }
  };

  const result = await baseQuery(queryArgs);

  if (result.error) {
    reportApiResponse('error');
    if (result.error.status === 429) {
      return { error: { status: 429, data: result.error.data } };
    } else {
      throw result.error;
    }
  }

  const resultData = result.data as {
    links: PaginationLink[];
    data: { id: string; status: string; jobData: T };
    metadata?: PaginationMetadata;
  };

  // if response is 200, the job was cached, so return the job data immediately
  // if response is 202, the job was queued, so poll for job data
  if (
    result.meta?.response?.status === 200 ||
    resultData.data.status === 'finished'
  ) {
    reportApiResponse('success');
    return { data: resultData.data.jobData };
  } else if (
    result.meta?.response?.status === 202 ||
    resultData.data.status === 'queued'
  ) {
    const data = result.data as {
      data: { id: string; status: string };
      links: PaginationLink[];
      metadata?: PaginationMetadata;
    };

    const links = data.metadata?.links ?? data.links;
    const statusUrl = links.find(({ rel }) => rel === 'status')?.href;
    if (!statusUrl) {
      return { error: { status: 404, data: 'No status url found' } };
    }

    const jobResult = await fetchJobResult<T>(baseQuery, statusUrl);
    if (jobResult) {
      reportApiResponse('success');
      return { data: jobResult };
    } else {
      reportApiResponse('error');
      return { error: { status: 500, data: `Job failed: ${statusUrl}` } };
    }
  }

  reportApiResponse('error');
  return { error: { status: 500, data: 'No server response' } };
};

export const {
  useAddInsightMutation,
  useAddInsightFavoriteMutation,
  useAddMetricAlertMutation,
  useAddTagsMutation,
  useAddTagTypeMutation,
  useAddUserMutation,
  useDeleteDataLinkMutation,
  useAddInsightSubscriptionMutation,
  useAddMetricAlertSubscriptionMutation,
  useDeleteInsightMutation,
  useDeleteSubscriptionMutation,
  useDeleteInsightSubscriptionsMutation,
  useDeleteInsightFavoriteMutation,
  useDeleteMetricAlertMutation,
  useDeleteSubscriptionsMutation,
  useDeleteTagsV2Mutation,
  useGetMeQuery,
  useGetFeaturesQuery,
  useGetDaysQuery,
  useGetDatesQuery,
  useGetPeriodsQuery,
  useUpdateUserMutation,
  useGetInsightQuery,
  useGetInsightSubscriptionsQuery,
  useGetLayerGroupQuery,
  useGetLayerGroupsQuery,
  useGetLayerSearchQuery,
  useGetMetricAlarmsQuery,
  useGetMetricAlarmIncidentsQuery,
  useGetMetricAlarmRecordsQuery,
  useGetMetricAlertQuery,
  useGetMetricAlertsQuery,
  useGetMetricAlertSubscriptionsQuery,
  useGetNonRoutedRouteDetailsQuery,
  useGetRoutesQuery,
  useGetRouteDescriptionQuery,
  useGetRouteDetailsV2Query,
  useGetRouteLoadV2Query,
  useGetRouteReferencesQuery,
  useGetRouteSummaryV2Query,
  useGetRouteSegmentsQuery,
  useGetRouteShapesQuery,
  useGetRouteTagsQuery,
  useGetStopQuery,
  useGetStopDetailQuery,
  useGetStopsQuery,
  useGetStopSummaryV2Query,
  useGetStopPointsQuery,
  useGetStopPointsV3Query,
  useGetStopReferencesQuery,
  useGetStopTagsQuery,
  useGetSubscriptionsQuery,
  useGetTagsQuery,
  useGetTagTypeV2Query,
  useGetTagTypesV2Query,
  useGetTripDetailsQuery,
  useGetTripDescriptionQuery,
  useGetTripSummaryQuery,
  useGetTimeRangesQuery,
  useGetVehiclesQuery,
  useGetVehicleSearchQuery,
  useUpdateTagTypeMutation,
  useUpdateMetricAlertMutation,
  useDeleteTagTypeMutation,
  useGetInsightsQuery,
  useGetUsersQuery,
  useDownloadInsightMutation,
  useDownloadMetricAlarmIncidentsMutation,
  useGetSystemQuery,
  useGetTripVariantQuery,
  useGetTripVariantsQuery,
  useUpdateInsightMutation,
  useUpdateSubscriptionMutation,
  useGetInsightResultsQuery,
  useLazyGetInsightQuery,
  useLazyGetInsightsQuery,
  useLazyGetInsightResultsQuery,
  useLazyGetMetricAlarmIncidentsQuery,
  useLazyGetMetricAlarmRecordsQuery,
  useLazyGetMetricAlertSubscriptionsQuery,
  useGetUnreadNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useMarkAllNotificationsAsReadMutation,
  useMuteMetricAlarmEntityMutation,
  usePostTrackingEventMutation,
  useUnmuteMetricAlarmEntityMutation,
  useRefreshTokenMutation,
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
} = api;
