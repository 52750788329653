import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  MenuList,
  MenuItem,
  Popover,
} from '@mui/material';
import {
  HelpOutline,
  Person as AccountIcon,
  PersonOutline as AccountIconOutline,
  MeetingRoomOutlined as Logout,
  SettingsOutlined,
  CheckOutlined,
  BusinessOutlined,
  AutoAwesome,
} from '@mui/icons-material';
import { BusAlert } from '../../icons';
import Submenu from '../Submenu';
import HeaderMenuButton from '../HeaderMenuButton';
import HeaderMenuButtonV2 from '../HeaderMenuButton/HeaderMenuButtonV2';
import BetaTag from '../BetaTag';
import {
  selectAgency,
  selectFullName,
  selectUser,
} from '../../store/slices/userSlice';
import { usePostTrackingEventMutation } from '../../store/slices/apiSlice';
import { useLogoutUser } from '../../utils/hooks/auth';
import { UserRole } from '../../store/types';
import {
  useFeatureEnabled,
  KNOWN_FEATURES,
} from '../../context/FeatureProvider';
import useCookie from '../../utils/hooks/useCookie';

type UserMenuButtonProps = { useV2?: boolean };

export default function UserMenuButton({ useV2 }: UserMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { email, userRole, userRoles = [], isStaff } = useSelector(selectUser);
  const { agencyId, isCleanseOnly } = useSelector(selectAgency);
  const fullName = useSelector(selectFullName);
  const [trackEvent] = usePostTrackingEventMutation();
  const { setCookie, cookieValue } = useCookie('MERGE_REDESIGN_OPT_IN');
  const logout = useLogoutUser();

  const showDiagnosticReporting = useFeatureEnabled(
    KNOWN_FEATURES.SHOW_DIAGNOSTIC_REPORTING
  );
  const showMerge = useFeatureEnabled(KNOWN_FEATURES.SHOW_MERGE_REDESIGN);

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const changeRole = (newRole: UserRole) => {
    localStorage.setItem('user_role', JSON.stringify(newRole));
    localStorage.setItem('agency_id', newRole.agency.id);
    openInNewTab(window.location.origin);
  };

  const activeRole = userRoles.find((role) => role.id === userRole?.id);
  const showAgencyItem = activeRole && userRoles.length === 1;
  const showAgencySwitcher = activeRole && userRoles.length > 1;

  const Button = useV2 ? HeaderMenuButtonV2 : HeaderMenuButton;
  const iconStyles = useV2
    ? {}
    : {
        fontSize: 'inherit',
        color: 'secondary.main',
      };

  return (
    <>
      <Button
        aria-controls='profile-menu'
        aria-label='Your agencies and settings'
        aria-haspopup='true'
        className=''
        color='inherit'
        isActive={!!anchorEl}
        onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
        title='Your agencies and settings'
        variant='dark'
      >
        {anchorEl ? (
          <AccountIcon sx={iconStyles} />
        ) : (
          <AccountIconOutline sx={iconStyles} />
        )}
      </Button>
      <Popover
        id='profile-menu'
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuList>
          <Box display='flex' alignItems='center' px={2} py={1}>
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <Box>
              {fullName && (
                <Box fontWeight={500} fontSize='1rem'>
                  {fullName}
                </Box>
              )}
              {email}
            </Box>
          </Box>
          <Divider />
          {showAgencyItem && (
            <MenuItem>
              <ListItemIcon>
                <BusinessOutlined />
              </ListItemIcon>
              {activeRole?.agency.name}
            </MenuItem>
          )}
          {showAgencySwitcher && (
            <Submenu
              autoFocus
              label={
                <>
                  <ListItemIcon>
                    <BusinessOutlined />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    {activeRole?.agency.name}
                  </ListItemText>
                </>
              }
              rtl
            >
              {[...userRoles]
                .sort((a, b) => (a.agency.name > b.agency.name ? 1 : -1))
                .map((role) => (
                  <MenuItem
                    key={role.id}
                    onClick={() => changeRole(role)}
                    disabled={role.id === userRole?.id}
                  >
                    {role.id === userRole?.id && (
                      <ListItemIcon>
                        <CheckOutlined style={{ height: 20, width: 20 }} />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      disableTypography
                      inset={role.id !== userRole?.id}
                    >
                      {role.agency.name}
                    </ListItemText>
                  </MenuItem>
                ))}
            </Submenu>
          )}
          {showDiagnosticReporting && (
            <MenuItem
              component={Link}
              to='/settings/vehicle_issues'
              onClick={() => setAnchorEl(null)}
            >
              <ListItemIcon>
                <BusAlert />
              </ListItemIcon>
              <ListItemText disableTypography style={{ paddingRight: 56 }}>
                Vehicle issues
              </ListItemText>
              <ListItemSecondaryAction>
                <BetaTag />
              </ListItemSecondaryAction>
            </MenuItem>
          )}
          {!isCleanseOnly && (
            <MenuItem
              component={Link}
              to='/settings'
              onClick={() => {
                setAnchorEl(null);
                trackEvent({ agencyId, name: 'settings_opened' });
              }}
            >
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              Settings
            </MenuItem>
          )}
          {showMerge && isStaff && (
            <MenuItem
              onClick={() => {
                if (!cookieValue || cookieValue === 'false') {
                  setCookie(true);
                  window.location.reload();
                } else {
                  setCookie(false);
                  window.location.reload();
                }
              }}
            >
              <ListItemIcon>
                <AutoAwesome />
              </ListItemIcon>
              {(!cookieValue || cookieValue === 'false') &&
                'Try the new design'}
              {cookieValue === 'true' && 'Take me back!'}
            </MenuItem>
          )}
          <MenuItem
            component='a'
            href='https://swiftly.zendesk.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <ListItemIcon>
              <HelpOutline />
            </ListItemIcon>
            Help center
          </MenuItem>
          <MenuItem onClick={() => logout()}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            Sign out
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
