import React, { Suspense, lazy } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
  useFeatureEnabled,
  KNOWN_FEATURES,
} from '../../context/FeatureProvider';

// components
import Header from '../Header/Header';
import LoadingSpinner from '../LoadingSpinner';

// pages
import useSyncHistoryWithStore from '../../utils/hooks/useSyncHistoryWithStore';
import { selectAgency } from '../../store/slices/userSlice';

const Analytics = lazy(
  () =>
    import(/* webpackChunkName: "Analyze" */ '../../pages/analytics/Analytics')
);
const Settings = lazy(
  () =>
    import(/* webpackChunkName: "Settings" */ '../../pages/settings/Settings')
);
const AllInsights = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsOverview" */ '../../pages/insights/AllInsights'
    )
);
const InsightsBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "InsightsDetails" */ '../../pages/insights/InsightsBuilder'
    )
);

function Layout(props: RouteComponentProps) {
  const { showTrips, showInsights, showAtypicalDays } =
    useSelector(selectAgency);

  const showDiagnosticReporting = useFeatureEnabled(
    KNOWN_FEATURES.SHOW_DIAGNOSTIC_REPORTING
  );

  useSyncHistoryWithStore();

  return (
    <Box
      display='flex'
      maxWidth='100vw'
      minHeight='100vh'
      overflow='hidden auto'
    >
      <Header />
      <Suspense
        fallback={
          <Box flexGrow={1}>
            <LoadingSpinner message='Loading...' showError={false} />
          </Box>
        }
      >
        <Box flexGrow={1} width='100vw' maxHeight='100vh' pt={6.5}>
          <Switch>
            <Route path='/system'>
              <Analytics leftPanel='systemFull' rightPanel='system' />
            </Route>
            <Route path={['/routes/summary', '/routes/tags']}>
              <Analytics leftPanel='routes' rightPanel='routes' />
            </Route>
            <Route exact path='/routes/trips'>
              {!showTrips ? (
                <Redirect to='/routes/summary' />
              ) : (
                <Analytics
                  {...props}
                  leftPanel={'routes'}
                  rightPanel={'routes'}
                />
              )}
            </Route>
            <Route path='/routes'>
              <Analytics leftPanel='systemRoutes' rightPanel='system' />
            </Route>
            <Route path='/trips/details'>
              <Analytics leftPanel='trips' rightPanel='routes' />
            </Route>
            <Route path='/stops/summary'>
              <Analytics leftPanel='stops' rightPanel='stops' />
            </Route>
            <Route path='/stops'>
              <Analytics leftPanel='systemStops' rightPanel='stops' />
            </Route>
            <Route path='/settings/profile'>
              <Settings rightPanel='profile' />
            </Route>
            <Route path='/settings/team'>
              <Settings rightPanel='team' />
            </Route>
            <Route
              path='/settings/routes/:id'
              render={({ match }) => (
                <Settings routeId={match.params.id} rightPanel='routeDetails' />
              )}
            />
            <Route path='/settings/routes'>
              <Settings rightPanel='routes' />
            </Route>
            <Route
              path='/settings/stops/:id'
              render={({ match }) => (
                <Settings stopId={match.params.id} rightPanel='stopDetails' />
              )}
            />
            <Route path='/settings/stops'>
              <Settings rightPanel='stops' />
            </Route>
            <Route path='/settings/tags/:id/add'>
              <Settings rightPanel='addTags' />
            </Route>
            <Route path='/settings/tags/:id'>
              <Settings rightPanel='tagDetails' />
            </Route>
            <Route path='/settings/tags'>
              <Settings rightPanel='tagTypes' />
            </Route>
            {showDiagnosticReporting && [
              <Route exact path='/settings/vehicles' key='vehicle-overview'>
                <Settings rightPanel='vehicles' />
              </Route>,
              <Route path='/settings/vehicles/:id' key='vehicle-details'>
                <Settings rightPanel='vehicleDetails' />
              </Route>,
              <Route exact path='/settings/vehicle_issues' key='vehicle-issues'>
                <Settings rightPanel='vehicleIssues' />
              </Route>,
            ]}
            {showAtypicalDays && (
              <Route path='/settings/holidays'>
                <Settings rightPanel='holidays' />
              </Route>
            )}
            <Route path='/settings/data/linked'>
              <Settings rightPanel='linkedData' />
            </Route>
            <Route path='/settings/data/unlinked'>
              <Settings rightPanel='unlinkedData' />
            </Route>
            <Route path='/settings/export'>
              <Settings rightPanel='export' />
            </Route>
            <Route exact path='/settings'>
              <Redirect to='/settings/profile' />
            </Route>
            {showInsights && [
              <Route exact path='/insights' key='insight-redirect'>
                <Redirect to='/insights/all' />
              </Route>,
              <Route exact path='/insights/all' key='insight-overview'>
                <AllInsights />
              </Route>,
              <Route path='/insights/:id' key='insight-details'>
                <InsightsBuilder />
              </Route>,
            ]}
            <Route path='/views/:id' key='view-redirect'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </Box>
      </Suspense>
    </Box>
  );
}

export default withRouter(Layout);

export { default as LayoutV2 } from './LayoutV2';
