import { Middleware } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { enqueueToast } from '../../store/slices/layoutSlice';

const apiMiddleware: Middleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload?.status !== 401) {
      let message = 'Sorry, a network error occurred. Please try again.';
      if (action.payload?.data?.name) {
        message = action.payload.data.name;
      }
      if (action.payload?.data?.detail) {
        message = action.payload.data.detail;
      }

      store.dispatch(
        enqueueToast({
          severity: 'error',
          message,
        })
      );
    }
  }

  return next(action);
};

export default apiMiddleware;
