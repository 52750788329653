import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useGetMeQuery,
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation,
} from '../../store/slices/apiSlice';
import usePrevious from './usePrevious';
import isEqual from 'react-fast-compare';
import { filterEmptyProperties } from '../utils';
import { persistSession, getActiveUserRole, getAgencyFromRole } from '../user';
import {
  updateUser,
  updateAccessToken,
  updateRefreshToken,
  updateIsAuthenticated,
  signOut,
} from '../../store/slices/userSlice';
import { RootState } from '../../store';
import tracking from '../tracking';
import * as Sentry from '@sentry/react';

export function useFetchUser({ skip }: { skip?: boolean }) {
  const dispatch = useDispatch();
  const {
    data: user,
    error,
    isFetching,
    isError,
  } = useGetMeQuery(undefined, {
    skip,
  });
  const previousUser = usePrevious(user);

  useEffect(() => {
    if (user && !isEqual(user, previousUser)) {
      const userRoles = user.user_roles;
      const userState = filterEmptyProperties({
        email: user.email,
        firstName: user.first_name,
        intercomHash: user.intercom_hash,
        lastName: user.last_name,
        userId: user.user_id,
        userRoles,
        username: user.username,
        isStaff: user.is_staff,
      });

      if (userRoles) {
        const userRole = getActiveUserRole(userRoles);

        if (userRole && userRole.agency) {
          persistSession(JSON.stringify(userRole), userRole.agency.id);
          userState.userRole = userRole || {};
          userState.agency = getAgencyFromRole(userRole);
        }
      }

      dispatch(updateUser(userState));
    }
  }, [dispatch, user, previousUser]);

  return { user, error, isFetching, isError };
}

export function useLoginUser() {
  const dispatch = useDispatch();
  const [loginUser, { error, isLoading, isError }] = useLoginMutation();

  const onLoginSuccess = useCallback(
    ({ access, refresh }: { access: string; refresh: string }) => {
      dispatch(updateAccessToken(access));
      dispatch(updateRefreshToken(refresh));
      dispatch(updateIsAuthenticated(true));
      tracking.event('analyze-login-success');
    },
    [dispatch]
  );

  const onLoginFailure = useCallback(() => {
    tracking.event('analyze-login-failed');
  }, []);

  const doLogin = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      try {
        const token = await loginUser({ username, password }).unwrap();
        if (token) {
          onLoginSuccess(token);
        }
      } catch (e) {
        if (e && typeof e === 'object' && 'status' in e) {
          if (e.status === 401) {
            onLoginFailure();
          }
        }
      }
    },
    [loginUser, onLoginFailure, onLoginSuccess]
  );

  return { login: doLogin, error, isLoading, isError };
}

export function useLogoutUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [logoutUser] = useLogoutMutation();
  const refreshToken = useSelector(
    (state: RootState) => state.user.refreshToken
  );

  const doLogout = useCallback(() => {
    Sentry.setUser(null);
    if (refreshToken) {
      logoutUser({ refresh: refreshToken });
    }
    dispatch(signOut());
    tracking.event('analyze-logout-success');
    history.push('/login');
  }, [dispatch, history, logoutUser, refreshToken]);

  return doLogout;
}

export function useResetPassword() {
  const [resetPassword, { error, isError, isLoading }] =
    useChangePasswordMutation();
  const dispatch = useDispatch();

  const doResetPassword = useCallback(
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      try {
        const token = await resetPassword({
          currentPassword,
          newPassword,
        }).unwrap();
        dispatch(updateAccessToken(token.access));
        dispatch(updateRefreshToken(token.refresh));
        return true;
      } catch {
        return false;
      }
    },
    [dispatch, resetPassword]
  );

  return { resetPassword: doResetPassword, error, isError, isLoading };
}
