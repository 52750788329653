import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateAccessToken,
  updateRefreshToken,
} from '../../store/slices/userSlice';
import { useRefreshTokenMutation } from '../../store/slices/apiSlice';
import { RootState } from '../../store';
import { useLogoutUser } from './auth';

export default function useMaintainTokens() {
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.user.accessToken);
  const refreshToken = useSelector(
    (state: RootState) => state.user.refreshToken
  );
  const logout = useLogoutUser();
  const interval = useRef<ReturnType<typeof setTimeout>>();

  const [getNewRefreshToken, { error }] = useRefreshTokenMutation();
  const updateTokens = useCallback(async () => {
    if (refreshToken) {
      try {
        const data = await getNewRefreshToken({
          refreshToken,
          accessToken,
        }).unwrap();
        if (data) {
          dispatch(updateAccessToken(data.access));
          dispatch(updateRefreshToken(data.refresh));
        }
      } catch (e) {
        if (error && 'status' in error) {
          if (error.status === 401) {
            logout();
          }
        }
      }
    }
  }, [dispatch, error, getNewRefreshToken, logout, refreshToken, accessToken]);

  useEffect(() => {
    updateTokens();

    interval.current = setInterval(() => {
      updateTokens();
    }, 3600000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [dispatch]);
}
