import React, { useState } from 'react';
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Divider,
} from '@mui/material';
import { withRouter } from 'react-router-dom';

// logo
import logo from '../../assets/hopthru.svg';

// context
import { enqueueToast } from '../../store/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import tracking from '../../utils/tracking';
import { useLoginUser } from '../../utils/hooks/auth';
import { useResetPasswordMutation } from '../../store/slices/apiSlice';

function Login() {
  // global
  const dispatch = useDispatch();
  const {
    login,
    isLoading: isLoginLoading,
    isError: isLoginError,
  } = useLoginUser();

  // local
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');
  var [forgotPassword, setForgotPassword] = useState(false);
  const [
    resetPassword,
    { isLoading: isResetPasswordLoading, isError: isResetPasswordError },
  ] = useResetPasswordMutation();

  const onEnterPressed = async () => {
    if (!forgotPassword) {
      tracking.event('analyze-login-clicked');
      login({ username: loginValue, password: passwordValue });
    } else {
      tracking.event('analyze-forgotPassword-clicked');
      try {
        const result = await resetPassword({ email: loginValue }).unwrap();
        if (result.status === 'OK') {
          setForgotPassword(false);
          dispatch(
            enqueueToast({ severity: 'info', message: 'Reset email sent' })
          );
        }
      } catch {
        // error handled by RTKQ hook
      }
    }
  };

  const logoImage = (
    <Box
      component='img'
      src={logo}
      alt='logo'
      sx={{
        width: 211,
        margin: 'auto',
      }}
    />
  );

  const divider = (
    <Divider
      sx={{
        mt: 4,
        mb: 1,
        width: '100%',
        height: 1,
        backgroundColor: 'tertiary.main',
      }}
    />
  );

  const loginForm = (
    <React.Fragment>
      {logoImage}
      <Typography
        color='secondary'
        variant='h2'
        style={{ margin: 'auto', marginTop: 16 }}
      >
        Welcome back
      </Typography>
      {divider}
      <Typography color='secondary' style={{ marginTop: 4 }}>
        Email address
      </Typography>
      <TextField
        id='email'
        value={loginValue}
        onChange={(e) => setLoginValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='email'
        variant='outlined'
        fullWidth
      />
      <Typography color='secondary' style={{ marginTop: 8 }}>
        Password
      </Typography>
      <TextField
        id='password'
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='password'
        variant='outlined'
        fullWidth
      />
      <Fade in={isLoginError}>
        <Typography color='error' sx={{ mt: 0.5, textAlign: 'center' }}>
          Incorrect username or password
        </Typography>
      </Fade>
      <Box sx={{ width: '100%', textAlign: 'center', alignItems: 'center' }}>
        <Button
          disableElevation
          style={{ width: '100%', height: 50 }}
          disabled={loginValue.length === 0 || passwordValue.length === 0}
          onClick={() => onEnterPressed()}
          variant='contained'
          color='secondary'
          sx={{ px: 2, py: 0.5, my: 1, fontWeight: 500 }}
        >
          Log in
        </Button>
        {isLoginLoading ? (
          <CircularProgress
            size={26}
            color='secondary'
            style={{ marginTop: 16 }}
          />
        ) : (
          <Button
            onClick={() => setForgotPassword(!forgotPassword)}
            style={{ borderRadius: 25 }}
            variant='outlined'
            color='tertiary'
            sx={{ px: 2, py: 0.5, my: 1, fontWeight: 500 }}
          >
            Forgot your password?
          </Button>
        )}
      </Box>
    </React.Fragment>
  );

  const resetForm = (
    <React.Fragment>
      {logoImage}
      <Typography
        color='secondary'
        variant='h2'
        style={{ margin: 'auto', marginTop: 16 }}
      >
        Reset password
      </Typography>
      {divider}
      <Typography color='secondary.main' sx={{ my: 1, textAlign: 'center' }}>
        Please enter the email address associated with your Hopthru account, and
        we’ll email you a link to reset your password.
      </Typography>
      <Typography color='secondary' style={{ marginTop: 4 }}>
        Email address
      </Typography>
      <TextField
        id='email'
        value={loginValue}
        onChange={(e) => setLoginValue(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onEnterPressed();
            ev.preventDefault();
          }
        }}
        type='email'
        variant='outlined'
        fullWidth
      />
      <Fade in={isResetPasswordError}>
        <Typography
          color='error'
          sx={{
            mt: 0.5,
            textAlign: 'center',
          }}
        >
          Email not found
        </Typography>
      </Fade>
      <Box sx={{ width: '100%', textAlign: 'center', alignItems: 'center' }}>
        <Button
          style={{ width: '100%', height: 50 }}
          disabled={loginValue.length === 0}
          onClick={() => onEnterPressed()}
          variant='contained'
          color='secondary'
          sx={{ px: 2, py: 0.5, my: 1, fontWeight: 500 }}
        >
          Send reset link
        </Button>
        {isResetPasswordLoading ? (
          <CircularProgress
            size={26}
            color='secondary'
            style={{ marginTop: 16 }}
          />
        ) : (
          <Button
            onClick={() => setForgotPassword(!forgotPassword)}
            style={{ borderRadius: 25 }}
            variant='outlined'
            color='tertiary'
            sx={{ px: 2, py: 0.5, my: 1, fontWeight: 500 }}
          >
            Return to login
          </Button>
        )}
      </Box>
    </React.Fragment>
  );

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '50%', lg: '100%' },
        }}
      >
        <Box
          sx={{
            width: 400,
            borderRadius: 2.5,
            py: 4,
            px: 2.5,
            border: 'solid 1.5px #dadce0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!forgotPassword ? loginForm : resetForm}
        </Box>
        <Typography
          color='secondary.main'
          sx={{
            mt: 4,
            whiteSpace: 'nowrap',
            position: { xs: 'static', md: 'absolute' },
            bottom: 16,
          }}
        >
          © 2025 Hopthru, Inc. All rights reserved.
        </Typography>
      </Box>
    </Grid>
  );
}

export default withRouter(Login);
