import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { RootState } from '..';

import { updateAccessToken, updateRefreshToken } from '../slices/userSlice';

function setPassword(
  token: string,
  password: string,
  register: boolean,
  setIsLoading: (isLoading: boolean) => void,
  setError: (error: any) => void,
  onSuccess?: () => void
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    setError(false);
    setIsLoading(true);

    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          password,
          register,
        }),
      };

      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/password_reset/confirm/`,
        requestOptions
      );
      const data = await response.json();
      if (response.ok) {
        dispatch(updateAccessToken(data.access));
        dispatch(updateRefreshToken(data.refresh));
        setIsLoading(false);
        onSuccess?.();
      } else {
        if (data.password) {
          setError(data.password);
        } else if (response.status === 404) {
          if (data.status === 'expired') {
            setError(
              'The link you clicked is no longer active. Please contact an admin for assistance.'
            );
          } else if (data.status === 'notfound') {
            setError(
              "We couldn't find an invitation associated with the link you clicked. Please login if you already have an account or contact an admin for assistance."
            );
          }
        } else {
          setError(response.statusText);
        }
        setIsLoading(false);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };
}

export { setPassword };
