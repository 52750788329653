import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';

type SegmentedControlItem = {
  value: string;
  label: string;
  Icon?: React.ReactElement;
  disabled?: boolean;
} & ButtonProps;

type SegmentedControlProps = {
  onChange: (e: React.MouseEvent<HTMLElement>, value: string) => void;
  value: string;
  items: SegmentedControlItem[];
};

export function SegmentedControl({
  value,
  onChange,
  items,
}: SegmentedControlProps) {
  const index = items.findIndex((item) => item.value === value);
  return (
    <Box
      sx={{
        borderRadius: 2.5,
        border: 1,
        borderColor: 'tertiary.main',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.25), 0 1px 1px #fff',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {items.map(({ value: buttonValue, label, disabled, Icon, ...rest }) => (
          <Button
            onClick={(e) => value !== buttonValue && onChange(e, buttonValue)}
            key={buttonValue}
            disabled={disabled}
            {...rest}
            sx={{
              flex: '1 1 0',
              fontWeight: 500,
              fontSize: '1rem',
              paddingX: 1.5,
              paddingY: 0.5,
              zIndex: 1,
              '&:focus': {
                outlineOffset: 0,
              },
            }}
            variant='text'
            color='secondary'
          >
            {Icon && React.cloneElement(Icon, { sx: { mr: 0.5 } })}
            {label}
          </Button>
        ))}
      </Box>
      <Box
        id='segmented-control-indicator'
        sx={{
          width: 'calc(50% - 2px)',
          height: 'calc(100% - 4px)',
          position: 'absolute',
          backgroundColor: 'primary.main',
          borderRadius: 2,
          border: 1,
          borderColor: 'tertiary.main',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
          pointerEvents: 'none',
          transform: `translateX(calc(${index} * 100%))`,
          transition: 'all ease-in-out 0.15s',
          top: 2,
          left: 2,
        }}
      />
    </Box>
  );
}
