import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { postEvent } from '../utils/api';
import LoadingSpinner from '../components/LoadingSpinner';
import * as Sentry from '@sentry/react';
import history from '../utils/history';

// components
import Layout, { LayoutV2 } from './Layout';
import PageTitle from './PageTitle';
import ErrorBoundry from '../utils/ErrorBoundary';

// pages
import ErrorPage from '../pages/error/Error';
import Login from '../pages/login/Login';
import Signup from '../pages/signup/Signup';
import Reset from '../pages/reset/Reset';
import Unsubscribe from '../pages/unsubscribe/Unsubscribe';

// context
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { selectAgency, selectFullName } from '../store/slices/userSlice';

import { useFeatureEnabled, KNOWN_FEATURES } from '../context/FeatureProvider';
import useCookie from '../utils/hooks/useCookie';
import { useFetchUser } from '../utils/hooks/auth';
import useMaintainTokens from '../utils/hooks/useMaintainTokens';
import Segment from '../utils/segment';
import { getActiveUserRole } from '../utils/user';

const SentryRoute = Sentry.withSentryRouting(Route);

function App() {
  // individually selected because the entire app will rerender when the tokens are updated otherwise
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  const email = useSelector((state: RootState) => state.user.email);
  const intercomHash = useSelector(
    (state: RootState) => state.user.intercomHash
  );
  const isStaff = useSelector((state: RootState) => state.user.isStaff);
  const { agencyId, isCleanseOnly } = useSelector(selectAgency);
  const fullName = useSelector(selectFullName);

  const enableMerge = useFeatureEnabled(KNOWN_FEATURES.SHOW_MERGE_REDESIGN);
  const { cookieValue } = useCookie('MERGE_REDESIGN_OPT_IN');
  const showMerge =
    enableMerge && (!isStaff || (isStaff && cookieValue === 'true'));

  const { user } = useFetchUser({ skip: !isAuthenticated });

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.user_id, email: user.email });
      Segment.identifyUser(user);
      const activeRole = getActiveUserRole(user.user_roles);
      if (activeRole.agency.swiftly_agency_key) {
        Segment.associateGroup(activeRole.agency.swiftly_agency_key, {
          groupType: 'agencyKey',
          groupValue: activeRole.agency.swiftly_agency_key,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (window.performance) {
      if (isAuthenticated) {
        if (performance.navigation.type === 1) {
          postEvent('app_reload');
          Segment.track('analyze-app-reload');
          const agencyId = sessionStorage.getItem('agency_id');
          const userRole = sessionStorage.getItem('user_role');
          sessionStorage.clear();
          agencyId && sessionStorage.setItem('agency_id', agencyId);
          userRole && sessionStorage.setItem('user_role', userRole);
        } else {
          postEvent('app_open');
        }
      }
    }
  }, [isAuthenticated]);

  window.addEventListener('beforeunload', (ev) => {
    if (isAuthenticated && performance.navigation.type !== 1) {
      postEvent('app_close');
    }
  });

  useEffect(() => {
    Segment.initialize(history);
    Segment.track('Detected user agent', {
      properties: {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        userAgent: window.navigator?.userAgent,
      },
    });
    const intercomData = isAuthenticated
      ? {
          user_id: email,
          email,
          name: fullName,
          agencyId,
          user_hash: intercomHash,
        }
      : {};

    window.Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_ID,
      ...intercomData,
    });
  }, [isAuthenticated]);

  useEffect(() => {
    Segment.setGlobalProperties({ version: showMerge ? 2 : 1 });
  }, [showMerge]);

  return (
    <ErrorBoundry>
      <PageTitle />
      {user || !isAuthenticated ? (
        <>
          <CleanseOnlyRedirect isCleanseOnly={isCleanseOnly} />
          <Switch>
            <PublicRoute path='/login' isAuthenticated={isAuthenticated}>
              <Login />
            </PublicRoute>
            <PublicRoute path='/signup' isAuthenticated={isAuthenticated}>
              <Signup />
            </PublicRoute>
            <PublicRoute path='/reset' isAuthenticated={isAuthenticated}>
              <Reset history={history} />
            </PublicRoute>
            <PublicRoute path='/unsubscribe'>
              <Unsubscribe />
            </PublicRoute>
            {!showMerge && !isCleanseOnly && (
              <SentryRoute
                exact
                path='/'
                render={() => <Redirect to='/system' />}
              />
            )}
            <PrivateRoute path='/' component={showMerge ? LayoutV2 : Layout} />
            <Route component={ErrorPage} />
          </Switch>
        </>
      ) : (
        <div style={{ height: '100vh' }}>
          <LoadingSpinner message={'App Loading...'} showError={false} />
        </div>
      )}
    </ErrorBoundry>
  );

  // #######################################################################
}

function PrivateRoute({ component, ...rest }: RouteProps) {
  useMaintainTokens();
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <SentryRoute
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          component && React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({
  children,
  isAuthenticated,
  ...rest
}: { isAuthenticated?: boolean } & RouteProps) {
  return (
    <SentryRoute
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <>{children}</>
        )
      }
    />
  );
}

function CleanseOnlyRedirect({ isCleanseOnly }: { isCleanseOnly?: boolean }) {
  const location = useLocation();
  if (isCleanseOnly && !location.pathname.match(/settings/)) {
    return <Redirect to='/settings' />;
  }
  return null;
}

export default Sentry.withProfiler(App);
